<template>
  <div class="about-page">
    <BaseSecondaryPagesHeader title="Обо мне" back-url="/"></BaseSecondaryPagesHeader>
    <About></About>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseSecondaryPagesHeader from '@/components/header/BaseSecondaryPagesHeader.vue';
import About from '@/components/secondary-pages/About.vue';

export default {
  name: 'Home',
  components: {
    BaseSecondaryPagesHeader, About,
  },
};
</script>
