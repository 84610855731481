<template>
    <div class="about-content">
        <WelcomeLandingAuthor></WelcomeLandingAuthor>
    </div>
</template>

<script>
import WelcomeLandingAuthor from '@/components/secondary-pages/welcome-landing/WelcomeLandingAuthor.vue';

export default {
  name: 'AboutPage',
  components: {
    WelcomeLandingAuthor,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  beforeDestroy() {
    this.$store.commit('showBurger');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/scss/common/variables";

.about-content {
    .wlm-author {
        padding-top: 0;

        .landing-title-group,
        .wlm-author__read-more {
            display: none;
        }
    }

    .wlm-author__content {
        margin-top: 30px;
    }

   .wlm-author__text-content {
       max-height: none;
   }
}
</style>
